
import commaNumber from 'comma-number'
import fireRTBHouseEvents from '@/helpers/fireRTBHouseEvents'
import analytics from '@/helpers/analytics'
import criteo from '@/helpers/criteo'
import fireConversionEvents from '@/helpers/fireConversionEvents'
import fireTaboolaEvents from '@/helpers/fireTaboolaEvents'

export default {
  props: {
    options: {
      type: Object,
      default: null
    },
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      maxShowPartners: 8,
      maxShowItems: 10,
      minPartnerItems: 2,
      minPercentGap: 0.15,
      maxPercentGap: 0.03,
      minPartnersGap: 3,
      pendingApproval: 0
    }
  },
  computed: {
    specialItemClass() {
      return (this.options && this.options.specialType) ? this.options.specialType : ''
    },
    isSponsored() {
      return this.options && this.options.specialType && (this.options.specialType === 'sponsored')
    },
    isVerified() {
      return this.options && this.options.specialType && (this.options.specialType === 'verified')
    },
    deeplinks() {
      return this.$store.state.deeplinks
    },
    firstResult () {
      let firstResult = this.pendingApproval ? this.item.partners[this.pendingApproval] : this.item.partners[0]
      return firstResult ?? this.item
    },
    minPriceFormatted () {
      return this.localCurrency ? commaNumber(this.firstResult.minPriceLocal) : commaNumber(this.firstResult.minPrice)
    },
    shouldShowPartnerName() {
      if (this.item.showPartnerName !== undefined) {
        return this.item.showPartnerName == 1
      }
      return this.item.cpc > 0
    },
    shouldShowPartnerList() {
      if (this.options && this.options.hideDropdown !== undefined) {
        return false
      }
      return this.item.partners && this.shouldShowPartnerName
    },
    parsedItems() {
      const partners = this.item.partners.slice(0, this.maxShowPartners)

      if (this.pendingApproval && partners.length >= this.minPartnersGap) {
        const pendingApprovalPartners = partners.splice(0, this.pendingApproval)
        partners.splice(1, 0, ...pendingApprovalPartners)
      }

      const parsedPartners = partners.slice(1).map((partnerItem) => {
        return { ...partnerItem, component: 'DropdownPartnerItem' }
      })

      const maxShowDiff = this.maxShowItems - parsedPartners.length

      const parsedCompare = this.$store.state.compareItems.slice(0, maxShowDiff).map((compareItem) => {
        return { ...compareItem, component: 'DropdownCompareItem' }
      })

      return parsedPartners.length > this.minPartnerItems ? [...parsedPartners] : [...parsedPartners, ...parsedCompare]
    },
    localCurrency () {
      return this.$store.state.localCurrency
    },
    displaySymbol () {
      return this.localCurrency ? this.item.currencySymbolLocal : this.item.currencySymbol || this.item.currency
    }
  },
  beforeMount () {
    this.isPendingApproval()
  },
  methods: {
    handleCardClick() {
      this.shouldShowPartnerName ? this.openPartnerDeeplinkUrl() : this.openCompareModal()
    },
    clickHandler() {
      return this.openPartnerDeeplinkUrl()
    },
    clickPhoneQuote() {
      analytics.track('meta-unit-phone-click', this)
      window.top.location = `tel:${this.item.phoneQuote.number}`

      try {
        // Taboola event
        fireTaboolaEvents('phone-clickout', this)
      } catch (error) {
        console.info('Error firing Taboola event', error)
      }
    },
    partnerClick (item) {
      if (item === undefined) {
        item = this.item
      }

      if (item.cpc === undefined || item.cpc <= 0) {
        return
      }

      const partnerCpc = Number(item.cpc).toFixed(2)
      const currency = 'USD'

      fireConversionEvents(this, {
        value: partnerCpc,
        currency: currency,
        bing: {
          eventName: "OTAclickout",
        },
      });

      // Criteo product tag
      this.pushCriteoParams()

      // Adara event
      this.fireAdaraEvent(this.item)

      // RTBHouse
      this.fireRTBHouseEvent()

      try {
        // Taboola event
        fireTaboolaEvents('ota-clickout', this)
      } catch (error) {
        console.info('Error firing Taboola event', error)
      }
    },
    openPartnerDeeplinkUrl() {
      // Skip triggering the event for fallback partner (cpc = 0)
      if (this.firstResult.cpc > 0) {
        this.partnerClick()
      }
      analytics.track('pricelink-click', this)
      console.info('Open partner deeplink URL')
      window.newTab = window.open(this.firstResult.partnerDeeplinkUrl, '_blank')
    },
    openCompareModal() {
      if (this.$store.state.isCompareToAdsModalLoaded) {
        this.$store.commit('setInlineAdDeeplink', this.item.partnerDeeplinkUrl)
        this.$emit('openCompareModal')
        return
      }

      // Fallback if compare modal is not loaded
      this.openApiDeeplinkUrls()
    },
    openApiDeeplinkUrls () {
      analytics.track('view-deals-click', this)
      try {
        // Taboola event
        fireTaboolaEvents('depplinks-clickout', this)
      } catch (error) {
        console.info('Error firing Taboola event', error)
      }
      console.info('Open API deeplink URLs')
      const deeplinks = this.item.apiDeeplinkUrls
      window.newTab = window.open('', '_blank')

      if (deeplinks[0]) {
        window.newTab.location.href = deeplinks[0].redirect
      }

      if (deeplinks[1]) {
        window.location.href = deeplinks[1].redirect
      }

      if (deeplinks[2] && window.popUnder) {
        window.popUnder.location.href = deeplinks[2].redirect
        window.popUnder.moveTo(0, 0)
        window.popUnder.resizeTo(screen.width, screen.height)
      }
    },
    compareAll (data) {
      this.$emit('compareAll', data)
    },
    pushCriteoParams () {
      const customerId = this.$store.state.entryId
      const currency = this.item.currency ?? 'USD'
      const id = customerId + this.item.uniqueId
      const price = this.item.cpc

      const options = { customerId, currency, price, id }
      criteo.purchaseTag(this.$store, options)
    },
    fireRTBHouseEvent () {
      try {
        // RTBHouse
        fireRTBHouseEvents('flight', 'conversion', {
          id: this.item.id,
          origin: this.item.legs[0].from.code,
          destination: this.item.legs[0].to.code,
          cpc: this.item.cpc,
          outboundDate: this.item.legs[0].depTime.split('T')[0],
          inboundDate: this.item.legs[1] ? this.item.legs[1].depTime.split('T')[0] : '',
          adults: this.$store.state.flightsSearchFormData.adults,
          children: this.$store.state.flightsSearchFormData.children,
          roundtrip: this.item.legs.length === 2
        })
      } catch (error) {
        console.info('Error firing RTB event')
      }
    },
    fireAdaraEvent (item) {
      if (Object.keys(item).length === 0) return

      const flightSearchData = this.$store.state.flightsSearchFormData

      const adaraParams = {
        travelProductType: 'Flight',
        tripType: item.legs.length === 2 ? 'roundtrip' : 'one-way',
        originAirportCode: item.legs[0].from.code,
        originCity: item.legs[0].from.city,
        // originCountry,
        // originState,
        destinationAirportCode: item.legs[1] ? item.legs[1].from.code : item.legs[0].to.code,
        destinationCity: item.legs[1] ? item.legs[1].from.city : item.legs[0].to.city,
        // destinationCountry,
        // destinationState,
        departureDate: item.legs[0].depTime.split('T')[0],
        returnDate: item.legs[1] ? item.legs[1].depTime.split('T')[0] : '',
        numPassenger: flightSearchData.adults + flightSearchData.children,
        numAdults: flightSearchData.adults,
        numChildren: flightSearchData.children,
        ticketClass: item.cabinClass,
        ticketPrice: item.minPrice,
        airlineBrand: item.legs[0].airline.name
      }
      try {
        window.adara('send', {
          pageType: 'Purchase Item',
          siteLanguage: this.$store.state.language,
          siteCountry: this.$store.state.country,
          ...adaraParams
        })
      } catch (e) {
        console.info('Error firing Adara event')
      }
    },
    isPendingApproval () {
      if (this.item.partners.length <= this.minPartnersGap) {
        return this.pendingApproval
      }
      const minGap = ((this.item.partners[1].minPrice / this.item.partners[0].minPrice) - 1) > this.minPercentGap
      const minGap2 = ((this.item.partners[2].minPrice / this.item.partners[0].minPrice) - 1) > this.minPercentGap
      const maxGap = ((this.item.partners[1].minPrice / this.item.partners[0].minPrice) - 1) < this.maxPercentGap

      if (minGap) {
        this.pendingApproval = 1
      } else if (maxGap && minGap2) {
        this.pendingApproval = 2
      }

      return this.pendingApproval
    }
  }
}
